<template>
  <div
    class="flex ml-1"
    @mouseover="showTooltip = true"
    @mouseout="showTooltip = false"
    :data-tooltip="'notesicon-' + _uid"
  >
    <div
      class="lg:ml-4 ml-1 meeting-icons cursor-pointer items-center flex relative"
      @click="$emit('click')"
    >
      <svg
        class="absolute right-0"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="#b5b5b5"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <rect x="5" y="3" width="14" height="18" rx="2" />
        <line x1="9" y1="7" x2="15" y2="7" />
        <line x1="9" y1="11" x2="15" y2="11" />
        <line x1="9" y1="15" x2="13" y2="15" />
      </svg>
    </div>
    <hover-tool-tip
      v-if="showLabel"
      :label="isActive ? 'Hide notes' : 'Show notes'"
      :selector="`[data-tooltip=&quot;notesicon-${_uid}&quot;`"
      :placement="isExtension ? 'bottom' : 'right'"
      :offset="5"
      url="https://help.meetric.app/en/articles/4794226-meeting-page-overview"
    ></hover-tool-tip>
  </div>
</template>

<script>
import HoverToolTip from '@/components/UI/HoverToolTip';

export default {
  name: 'MCI-Notes',
  components: {
    HoverToolTip,
  },
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  computed: {
    isExtension() {
      return !!this.$store?.getters['isExtension'];
    },
  },
};
</script>

<style scoped>
.meeting-icons {
  height: 1.35rem;
  width: 1.35rem;
}
.meeting-icons > svg:hover {
  @apply stroke-current text-grey2;
}
</style>

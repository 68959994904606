<template>
  <div
    @click="$emit('click')"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    class="flex ml-1 cursor-pointer"
    :class="[allCompleted ? 'all-completed' : 'all-not-completed']"
    style="min-width:fit-content;"
    :data-tooltip="'actionicon-' + _uid"
  >
    <div class="lg:ml-4 ml-1 meeting-icons relative">
      <svg
        class="absolute right-0"
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <filter
            x="-0.9%"
            y="-4.2%"
            width="101.9%"
            height="108.4%"
            filterUnits="objectBoundingBox"
            id="filter-1"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0.879250502   0 0 0 0 0.94604388   0 0 0 0 0.980015851  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
        </defs>
        <g
          id="Desktop-v1.2"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Desktop-HD---Meeting-Start-v1.2"
            transform="translate(-591.000000, -45.000000)"
          >
            <g
              id="Meeting-List-Item"
              transform="translate(124.000000, 25.000000)"
            >
              <path
                d="M479.5,22 L479.75516,22.0037566 C484.331549,22.1386785 488,25.890931 488,30.5 L488,30.5 L487.996243,30.7551604 C487.861321,35.3315491 484.109069,39 479.5,39 C474.805577,39 471,35.1944225 471,30.5 C471,25.8055775 474.805577,22 479.5,22 L479.5,22 Z M479.5,23.7 C475.744462,23.7 472.7,26.744462 472.7,30.5 C472.7,34.255538 475.744462,37.3 479.5,37.3 L479.5,37.3 L479.738727,37.2958878 C483.383711,37.1701265 486.3,34.1756329 486.3,30.5 L486.3,30.5 L486.295887,30.261273 C486.170126,26.6162886 483.175633,23.7 479.5,23.7 Z M481.863064,27.3439613 C482.16117,27.0463859 482.640742,27.0075034 482.985,27.27 C483.360554,27.5563565 483.43286,28.0929462 483.1465,28.4685 L483.1465,28.4685 L479.262,33.5685 C479.101526,33.77696 478.853572,33.8993677 478.5905,33.9000124 L478.5905,33.9000124 L478.479441,33.8933315 C478.259864,33.8656113 478.058027,33.7529442 477.919,33.577 L477.919,33.577 L475.8535,30.9335 L475.8535,30.9335 L475.788696,30.8376094 C475.578006,30.475235 475.662296,30.0038979 476.00225,29.73925 C476.373109,29.4505433 476.907793,29.5171408 477.1965,29.888 L477.1965,29.888 L477.1965,29.888 L478.582,31.6815 L481.795,27.4315 L481.7865,27.4315 Z"
                id="Fill"
                fill="#B5B5B5"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div>
      <transition name="fade">
        <p v-show="hover || captionExpanded" class="hidden lg:flex ml-1">
          {{ getActionCaption }}
        </p>
      </transition>
    </div>

    <hover-tool-tip
      v-if="showLabel"
      :label="isActive ? 'Show notes' : 'Show actions only'"
      :selector="`[data-tooltip=&quot;actionicon-${_uid}&quot;`"
      :placement="isExtension ? 'bottom' : 'right'"
      :offset="7"
      url="https://help.meetric.app/en/articles/4794226-meeting-page-overview"
    ></hover-tool-tip>
  </div>
</template>

<script>
import HoverToolTip from '@/components/UI/HoverToolTip';
export default {
  name: 'MCI-Actions',
  components: {
    HoverToolTip,
  },
  props: {
    actionsTotal: {
      type: Number,
      required: true,
    },
    actionsCompleted: {
      type: Number,
      required: true,
    },
    captionExpanded: {
      // if true, hover is ignored as the caption is kept expanded
      type: Boolean,
      required: false,
      default: false,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    isExtension() {
      return !!this.$store?.getters['isExtension'];
    },
    allCompleted: function() {
      return (
        this.actionsTotal == this.actionsCompleted && this.actionsTotal !== 0
      );
    },
    getActionCaption: function() {
      return this.actionsTotal === 0
        ? ''
        : `${this.actionsCompleted}/${this.actionsTotal} actions`;
    },
  },
  methods: {},
};
</script>

<style scoped>
.meeting-icons {
  margin-top: 0.1rem;
  height: 1.35rem;
  width: 1.35rem;
}

/* At first I used text-alertvalidation and opacity to get two different greens */
/* but opacity messed up transition which uses opacity... */
/* For that reason I didn't use opacity but 2 different green codes */
.all-completed .meeting-icons path {
  @apply fill-current text-alertvalidation;
}
.all-completed p,
.all-completed:hover p,
.all-completed.is-active p,
.all-completed:hover .meeting-icons path,
.all-completed.is-active .meeting-icons path {
  @apply text-alertvalidation;
}

.all-not-completed .meeting-icons path {
  @apply fill-current text-grey3;
}
.all-not-completed p {
  @apply text-grey3;
}
.all-not-completed:hover .meeting-icons path,
.all-not-completed.is-active .meeting-icons path {
  @apply fill-current text-grey2;
}
.all-not-completed:hover p,
.all-not-completed.is-active p {
  @apply text-grey2;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
